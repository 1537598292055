import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const BlogPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Blog" description='Welcome to my blog. It is modest, but bold.' />
      <div id="blog">
        <h1>The Blog.</h1>

        <ul>
        {data.allMarkdownRemark.edges.map(post => (
          <li key={post.node.id}>
          <div className="blog_preview">
            <h3 className="blog_title">
              <Link to={post.node.frontmatter.path}>
                {post.node.frontmatter.title}
              </Link>
            </h3>
            <h4 className="blog_tagline">{post.node.frontmatter.tagline}</h4>
            <p>{post.node.excerpt}</p>

            <p className="blog_credit">
              by {post.node.frontmatter.author} on {post.node.frontmatter.date}
            </p>

            {post.node.frontmatter.tags.map(tag => (

              <span className="blog_tag" key={tag}>
                {tag}
              </span>
            ))}

            <p className="small_link">
              [
              <Link className="small_link" to={post.node.frontmatter.path}>
                Read More
              </Link>
              ]
            </p>
            {/* <hr /> */}
          </div>
          </li>
        ))}
        </ul>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogIndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          frontmatter {
            author
            date(formatString: "MMMM Do, YYYY")
            path
            tags
            title
            tagline
          }
          excerpt
          id
        }
      }
    }
  }
`

export default BlogPage
